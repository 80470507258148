@media (max-width: 850px) {


	.section-services {
		overflow: hidden;
		padding: 30px 15px;
		&__main {
			flex-direction: column;
		}
	}

	.slider-services-nav {
		width: 100%;
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		&>li {
			flex-shrink: 0;
			width: auto;
			margin-right: 5px;
			margin-bottom: 10px;
			&:last-child {
				margin-right: 0;	
			}
			&:after {
				display: none;
			}
			&:before {
				display: none;
			}
			&>a {
				font-size: 16px;
				line-height: 20px;
				padding: 5px 10px;
			}
		}
	}

	.slider-services-main {
		padding: 15px;
		&__img {
			margin-bottom: 15px;
		}
		&__text {
			p {
				font-size: 18px;
				line-height: 20px;
			}
		}
	}


}