/****************************************************
fonts
****************************************************/
@font-face {
  font-family: 'Myriad Pro Cond';
  src: url("../fonts/MyriadPro-BoldCond.eot");
  src: local("Myriad Pro Bold Condensed"), local("MyriadPro-BoldCond"), url("../fonts/MyriadPro-BoldCond.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-BoldCond.woff2") format("woff2"), url("../fonts/MyriadPro-BoldCond.woff") format("woff"), url("../fonts/MyriadPro-BoldCond.ttf") format("truetype"), url("../fonts/MyriadPro-BoldCond.svg#MyriadPro-BoldCond") format("svg");
  font-weight: bold;
  font-style: normal;
}

/****************************************************
services
****************************************************/
.section-services {
  background: #ccc;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 40px 40px 40px 0;
  background: #fff url(../img/bg-services.jpg) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.section-services__main {
  display: flex;
  width: 100%;
}

.slider-services-nav {
  padding: 0 20px 0 0;
  list-style: none;
  flex-shrink: 0;
  width: 300px;
  box-sizing: border-box;
  margin: 0 45px 0 0;
}

.slider-services-nav > li {
  cursor: pointer;
  margin-bottom: 9px;
  position: relative;
  z-index: 10;
}

.slider-services-nav > li:last-child {
  margin-bottom: 0;
}

.slider-services-nav > li > a {
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-family: 'Myriad Pro Cond';
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 13px;
  padding-bottom: 9px;
  padding-left: 25px;
  padding-right: 5px;
  background: rgba(154, 154, 154, 0.4);
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #000;
  position: relative;
  z-index: 15;
  transition: .3s;
}

.slider-services-nav > li:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  width: 25px;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: -2;
}

.slider-services-nav > li:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -15px;
  width: 34px;
  height: 34px;
  background: rgba(154, 154, 154, 0.4);
  border-radius: 3px;
  transform: translateY(-50%) rotate(45deg);
  z-index: -5;
  transition: .3s;
}

.slider-services-nav > li:hover > a, .slider-services-nav > li.current > a {
  background: rgba(101, 186, 228, 0.6);
}

.slider-services-nav > li:hover:after, .slider-services-nav > li.current:after {
  background: rgba(101, 186, 228, 0.6);
}

.slider-services-main {
  flex-grow: 1;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  background: rgba(154, 154, 154, 0.4);
  clip-path: polygon(0% 25px, 25px 0%, calc(100% - 25px) 0%, 100% 25px, 100% calc(100% - 25px), calc(100% - 25px) 100%, 25px 100%, 0% calc(100% - 25px));
}

.slider-services-main__img {
  margin-bottom: 70px;
}

.slider-services-main__img img {
  max-width: 100%;
}

.slider-services-main__text p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Myriad Pro Cond';
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000;
}

.tab-block {
  display: none;
}

@media (max-width: 850px) {
  .section-services {
    overflow: hidden;
    padding: 30px 15px;
  }
  .section-services__main {
    flex-direction: column;
  }
  .slider-services-nav {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .slider-services-nav > li {
    flex-shrink: 0;
    width: auto;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .slider-services-nav > li:last-child {
    margin-right: 0;
  }
  .slider-services-nav > li:after {
    display: none;
  }
  .slider-services-nav > li:before {
    display: none;
  }
  .slider-services-nav > li > a {
    font-size: 16px;
    line-height: 20px;
    padding: 5px 10px;
  }
  .slider-services-main {
    padding: 15px;
  }
  .slider-services-main__img {
    margin-bottom: 15px;
  }
  .slider-services-main__text p {
    font-size: 18px;
    line-height: 20px;
  }
}
