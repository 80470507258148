/****************************************************
services
****************************************************/

.section-services {
	background: #ccc;
	min-height: 100vh;
	box-sizing: border-box;
	padding: 40px 40px 40px 0;
	background: #fff url(../img/bg-services.jpg) no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	&__main {
		display: flex;
		width: 100%;
	}
}

.slider-services-nav {
	padding: 0 20px 0 0;
	list-style: none;
	flex-shrink: 0;
	width: 300px;
	box-sizing: border-box;
	margin: 0 45px 0 0;
	&>li {
		cursor: pointer;
		margin-bottom: 9px;
		position: relative;
		z-index: 10;
		&:last-child {
			margin-bottom: 0;	
		}
		&>a {
			width: 100%;
			box-sizing: border-box;
			display: block;
			font-family: 'Myriad Pro Cond';
			text-decoration: none;
			text-transform: uppercase;
			padding-top: 13px;
			padding-bottom: 9px;
			padding-left: 25px;
			padding-right: 5px;
			background: rgba(154,154,154,.4);
			font-weight: 700;
			font-size: 24px;
			line-height: 24px;
			color: #000;
			position: relative;
			z-index: 15;
			transition: .3s;
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0px;
			width: 25px;
			height: 100%;
			background: rgba(255,255,255,.9);
			z-index: -2;
		}
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			right: -15px;
			width: 34px;
			height: 34px;
			background: rgba(154,154,154,.4);
			border-radius: 3px;
			transform: translateY(-50%) rotate(45deg);
			z-index: -5;
			transition: .3s;
		}

		&:hover, &.current {
			&>a {
				background: rgba(101,186,228,.6);
			}
			&:after {
				background: rgba(101,186,228,.6);
			}
		}
	}
}

.slider-services-main {
	flex-grow: 1;
	padding: 30px;
	box-sizing: border-box;
	position: relative;
	background: rgba(154,154,154,.4);
	clip-path: polygon(0% 25px, 25px 0%, calc(100% - 25px) 0%, 100% 25px, 100% calc(100% - 25px), calc(100% - 25px) 100%, 25px 100%, 0% calc(100% - 25px));
	&__img {
		margin-bottom: 70px;
		img {
			max-width: 100%;
		}
	}
	&__text {
		p {
			margin-top: 0;
			margin-bottom: 0;
			font-family: 'Myriad Pro Cond';
			font-weight: 700;
			font-size: 24px;
			line-height: 28px;
			color: #000;
		}
	}
}



.tab-block {
	display: none;
}

