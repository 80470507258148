/****************************************************
fonts
****************************************************/

@font-face {
    font-family: 'Myriad Pro Cond';
    src: url('../fonts/MyriadPro-BoldCond.eot');
    src: local('Myriad Pro Bold Condensed'), local('MyriadPro-BoldCond'),
        url('../fonts/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MyriadPro-BoldCond.woff2') format('woff2'),
        url('../fonts/MyriadPro-BoldCond.woff') format('woff'),
        url('../fonts/MyriadPro-BoldCond.ttf') format('truetype'),
        url('../fonts/MyriadPro-BoldCond.svg#MyriadPro-BoldCond') format('svg');
    font-weight: bold;
    font-style: normal;
}



